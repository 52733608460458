<template>
  <v-app>
    <template v-if="alertMessage">
      <v-alert dense class="text-center custom-alert" v-html="(alertDate ? alertDate + ':\n' : '') + alertMessage"></v-alert>
    </template>
    <!--    <h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated mb-5"-->
    <!--        style="&#45;&#45;fontSize:18; line-height: 1.5; &#45;&#45;minFontSize:18; color: #e9048b;">{{ date }}</h2>-->
    <!--    <v-text-field v-model="day" @change="load()"></v-text-field>-->
    <!--    <v-skeleton-loader v-if="loading" type="table">-->
    <!--    </v-skeleton-loader>-->
    <!--    <div v-else>-->
    <!--      <div v-for="item in list.items" :key="item.id">-->
    <!--        <v-divider></v-divider>-->
    <!--        <div class="itemRow my-2">-->
    <!--          <component-->
    <!--              :data="item.element"-->
    <!--              :item="item"-->
    <!--              :is="item.element.__typename"-->
    <!--          >-->
    <!--          </component>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-for="item in list.infoItems" :key="item.id">-->
    <!--        <v-divider></v-divider>-->
    <!--        <div class="itemRow">-->
    <!--          <component-->
    <!--              :data="item.element"-->
    <!--              :is="item.element.__typename"-->
    <!--          >-->
    <!--          </component>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </v-app>
</template>

<script>
import DayQuery from "@/graphql/queries/day.graphql"
// import object_Course from "@/components/items/object_Course"
// import object_Track from "@/components/items/object_Track"
// import object_Location from "@/components/items/object_Location"
// import object_InfoItem from "@/components/items/object_InfoItem"
// import object_Weather from "@/components/items/object_Weather"
// import object_Map from "@/components/items/object_Map"
// import State from "@/components/ui/state"
// import Start from "@/components/ui/start"
// import icon from "@/components/ui/icon"

export default {
  name: "Page",
  data: () => ({
    dialog: false,
    loading: false,
    day: "",
    list: "",
    date: "",
    alertMessage: "",
    alertDate: "",
  }),
  components: {
    // State,
    // Start,
    // icon,
    // object_Course,
    // object_Track,
    // object_Location,
    // object_InfoItem,
    // object_Weather,
    // object_Map,
  },
  mounted() {
    this.load()
  },
  filters: {
    getGermanDate: function (date) {
      if (date) {
        let parsedDate = new Date(date)

        let year = parsedDate.getFullYear()
        let month = `${parsedDate.getMonth() + 1}`.padStart(2, "0")
        let day = `${parsedDate.getDate()}`.padStart(2, "0")

        return `${day}.${month}.${year}`
      }
    }
  },
  methods: {
    load() {
      //current date
      var currentDate = new Date();
      this.date = currentDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
          this.$options.filters.getGermanDate(currentDate);

      //current date
      this.day =
          '/days/' +
          currentDate.getFullYear() +
          '/' +
          ('0' + (currentDate.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + currentDate.getDate()).slice(-2)

      this.loading = true
      this.$apollo
          .query({
            query: DayQuery,
            variables: {
              day: this.day
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.alertDate = response.data.getAlert.date
            this.list = response.data.getDay
            this.alertMessage = response.data.getAlert.alertText
            this.loading = false
          })
    }
  }
}
</script>

<style type="css">
:root {
  --primary_color: #e9048b;
}

.itemRow {
  min-height: 40px;
  margin-top: 10px;
}

.custom-alert {
  background-color: var(--primary_color) !important;
  color: white !important;
}

.custom-alert p {
  margin-bottom: 0;
}
</style>
